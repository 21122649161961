html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.insights-container {
    width: 100%;
    padding: 10px;
    font-family: sans-serif;
    display: flex;
    text-align: center;
}

.insight-container {
  padding: 5px;
  flex-grow: 1;
}

.insights-group {
  display: flex;
  flex-grow: 1;
}
.insights-group-inner {
  margin-top: 10px;
  display: flex;
}
.insights-group > div {
  width: 90%;
}
.insights-group-inner > div:not(:first-child) {
  border-left: 1px solid lightgray;
}



.insight-status {
   color: rgb(100, 100, 100);
   font-weight: bold;
   margin-top: 5px;
   padding: 3px;
   font-size: 75%;
   border-radius: 3px;
}

.insight-value-status {
  margin: 0;
  font-size: x-large;

}

.insight-value-state {
  margin: 0;
  font-size: xx-large;
}


.parentContainer {
  height: 600px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.barsContainer {
  flex: 0 0 auto;
  width: 70%;
}

.doughnutContainer {
  flex: 0 0 auto;
  width: 25%;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 100vh;
}
.spinnerBackdrop {
  width: 100%;
  position: absolute;
  background-color: rgb(248, 248, 249, 0.9);
}

